import { Amount } from 'allstake-sdk';
import { sharesToBalance } from 'allstake-sdk/dist/ethereum';
import Big from 'big.js';
import BN from 'bn.js';
import { useEffect, useRef, useState } from 'react';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import { AllstakeContainer } from '@/context/AllstakeContext';
import SymbolInputContext from '@/context/SymbolInputContext';
import { useWalletSelector } from '@/context/WalletSelectorContext';
import { getErc20Balance } from '@/utils/erc20';
import { formatShownDigit } from '@/utils/number';
import { SECOND, sleep } from '@/utils/time';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';

const { useSymbolInputTracked } = SymbolInputContext;

interface AmountData {
  withdrawAmount: {
    raw: BN;
    formatted: string;
  };
  withdrawAvailableTime: number;
  disabledWithdrawDueTime: boolean;
}

function useWithdrawAmount(
  refresh?: boolean,
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const { sdk: allstakeSdk } = AllstakeContainer.useContainer();
  const wallet = useWallet();
  const { accountId } = useWalletSelector();
  const { address: ethAddress } = useAccount();
  const [symbolInput] = useSymbolInputTracked();

  const refreshTimerRef = useRef<any>(null);
  const refreshingRef = useRef(false);
  const [data, setData] = useState<Record<string, AmountData>>({});

  const _refreshSolanaWithdrawData = async () => {
    if (!symbolInput) return;
    if (!wallet?.publicKey) return;

    if (!allstakeSdk || !allstakeSdk.solana || !allstakeSdk.solana.strategyManagerProgram) {
      return;
    }

    const amount = await allstakeSdk.solana.strategyManagerProgram.getQueuedWithdrawAmount(
      new PublicKey(symbolInput.address),
      wallet.publicKey,
    );
    const timestamp = await allstakeSdk.solana.strategyManagerProgram.getWithdrawAvailableTime(
      new PublicKey(symbolInput.address),
      wallet.publicKey,
    );

    return {
      withdrawAmount: {
        raw: amount,
        formatted: formatShownDigit(Amount.format(amount.toString(), symbolInput.decimals)),
      },
      withdrawAvailableTime: timestamp,
      disabledWithdrawDueTime: timestamp > Date.now(),
    };
  };

  const _refreshNearWithdrawData = async () => {
    if (!accountId) return;
    if (!symbolInput) return;
    if (!allstakeSdk || !allstakeSdk.near || !allstakeSdk.near.strategyManagerContract) {
      return;
    }

    // get withdraw amount
    const strategies = await allstakeSdk.near.strategyManagerContract.getStrategies({});
    let amount = Big(0);
    const targetStrategies: string[] = [];
    const targetStrategiesStartAt: number[] = [];
    const minimalWithdrawalDelay =
      await allstakeSdk.near.strategyManagerContract.getMinimumWithdrawalDelay({});
    const withdrawal = await allstakeSdk.near.strategyManagerContract.getQueuedWithdrawals({
      staker: accountId,
    });
    withdrawal.forEach((withdraw) => {
      const withdrawStrategies = withdraw.strategies;
      const withdrawShares = withdraw.shares;
      withdrawStrategies.forEach((withdrawStrategyId, idx) => {
        const strategy = strategies.find((strategy) => strategy.id === withdrawStrategyId);
        if (strategy && strategy.underlying_token === symbolInput.address) {
          amount = amount.plus(withdrawShares[idx]);
          targetStrategies.push(strategy.id);
          targetStrategiesStartAt.push(withdraw.start_at);
        }
      });
    });

    // getWithdrawAvailableTime = Max(getMinimumWithdrawalDelay, getStrategiesWithdrawalDelay) + Max(withdrawals.start_at)
    const delayTimes = await allstakeSdk.near.strategyManagerContract.getStrategiesWithdrawalDelay({
      strategies: targetStrategies,
    });
    const timestamp =
      Math.max(...delayTimes, minimalWithdrawalDelay) + Math.max(...targetStrategiesStartAt);

    return {
      withdrawAmount: {
        raw: new BN(amount.toFixed()),
        formatted: formatShownDigit(Amount.format(amount.toFixed(), symbolInput.decimals)),
      },
      withdrawAvailableTime: timestamp,
      disabledWithdrawDueTime: timestamp > Date.now(),
    };
  };

  const _refreshEthWithdrawData = async () => {
    if (!ethAddress) return;
    if (!symbolInput) return;
    if (
      !allstakeSdk ||
      !allstakeSdk.ethereum ||
      !allstakeSdk.ethereum.strategyManagerContract ||
      !allstakeSdk.ethereum.uiDataProviderContract
    ) {
      return;
    }

    const [requests, strategyData, userStrategyData] = await Promise.all([
      allstakeSdk.ethereum.uiDataProviderContract.userQueueWithdrawalRequests(
        symbolInput.address,
        ethAddress,
      ),
      allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(symbolInput.address),
      allstakeSdk.ethereum.uiDataProviderContract.userStrategyData(symbolInput.address, ethAddress),
    ]);
    const withdrawAvailableTimeRaw =
      Math.max(...requests.map((request) => Number(request.timestamp))) +
      Math.max(Number(strategyData.strategyWithdrawDelay), Number(strategyData.minWithdrawDelay));

    const queuedWithdrawShares = requests.reduce((prev, cur) => {
      return prev + cur.shares;
    }, BigInt(0));
    const withdrawAvailableTime = withdrawAvailableTimeRaw * SECOND;
    const disabledWithdrawDueTime = withdrawAvailableTime > Date.now();
    const strategyTotalBalance = await getErc20Balance(
      symbolInput.address as Address,
      userStrategyData.strategy as Address,
    );
    const withdrawAmount = sharesToBalance(queuedWithdrawShares, {
      strategyTotalBalance,
      strategyTotalShares: strategyData.strategyTotalShares,
    });
    return {
      disabledWithdrawDueTime,
      withdrawAmount: {
        raw: new BN(withdrawAmount.toString()),
        formatted: formatShownDigit(Amount.format(withdrawAmount.toString(), symbolInput.decimals)),
      },
      withdrawAvailableTime,
    };
  };

  const _handleRefreshWithdrawData = async () => {
    if (!symbolInput) return;
    if (refreshingRef.current) return;
    try {
      refreshingRef.current = true;
      if (!symbolInput) return;
      if (symbolInput.chain === 'solana') {
        return await _refreshSolanaWithdrawData();
      } else if (symbolInput.chain === 'near') {
        return await _refreshNearWithdrawData();
      } else if (symbolInput.chain === 'eth') {
        return await _refreshEthWithdrawData();
      }
    } catch (err) {
      console.warn('refresh failed', err);
    } finally {
      refreshingRef.current = false;
    }
  };
  const _handleRefreshWithdrawDataRef = useRef(_handleRefreshWithdrawData);
  _handleRefreshWithdrawDataRef.current = _handleRefreshWithdrawData;
  const _refreshWithdrawDataLoop = async () => {
    if (!symbolInput) return;
    if (!refresh || !setRefresh) return;

    try {
      await sleep(0.5 * SECOND);
      const newData = await _handleRefreshWithdrawDataRef.current();
      if (!newData) {
        setRefresh(false);
        return;
      }

      if (
        newData.withdrawAmount !== data[symbolInput.address]?.withdrawAmount ||
        newData.disabledWithdrawDueTime !== data[symbolInput.address]?.disabledWithdrawDueTime ||
        newData.withdrawAvailableTime !== data[symbolInput.address]?.withdrawAvailableTime
      ) {
        setRefresh(false);
        setData((prev) => ({
          ...prev,
          [symbolInput.address]: { ...newData },
        }));
        return;
      }

      refreshTimerRef.current = setInterval(() => {
        _handleRefreshWithdrawDataRef.current().then((newData2) => {
          if (!refresh) {
            clearInterval(refreshTimerRef.current);
            refreshTimerRef.current = null;
            return;
          }
          if (!newData2) {
            setRefresh(false);
            clearInterval(refreshTimerRef.current);
            refreshTimerRef.current = null;
            return;
          }

          if (
            newData2.withdrawAmount !== data[symbolInput.address]?.withdrawAmount ||
            newData2.disabledWithdrawDueTime !==
              data[symbolInput.address]?.disabledWithdrawDueTime ||
            newData2.withdrawAvailableTime !== data[symbolInput.address]?.withdrawAvailableTime
          ) {
            setRefresh(false);
            clearInterval(refreshTimerRef.current);
            refreshTimerRef.current = null;
            setData((prev) => ({
              ...prev,
              [symbolInput.address]: { ...newData2 },
            }));
          }
        });
      }, 2 * SECOND);
    } catch (err) {
      setRefresh(false);
      clearInterval(refreshTimerRef.current);
      refreshTimerRef.current = null;
      console.warn(err);
    }
  };
  const _refreshWithdrawDataLoopRef = useRef(_refreshWithdrawDataLoop);
  _refreshWithdrawDataLoopRef.current = _refreshWithdrawDataLoop;

  useEffect(() => {
    if (!symbolInput || !allstakeSdk) return;
    _handleRefreshWithdrawDataRef.current().then((_data) => {
      if (_data) {
        setData((prev) => ({
          ...prev,
          [symbolInput.address]: { ..._data },
        }));
      }
    });
  }, [symbolInput, allstakeSdk, wallet.publicKey, ethAddress, accountId]);

  useEffect(() => {
    if (!refresh) return;
    _refreshWithdrawDataLoopRef.current();
  }, [refresh]);

  useEffect(() => {
    if (refresh) return;
    clearInterval(refreshTimerRef.current);
    refreshTimerRef.current = null;
  }, [refresh]);

  useEffect(() => {
    return () => {
      clearInterval(refreshTimerRef.current);
      refreshTimerRef.current = null;
    };
  }, []);

  return {
    dataCompleted: !!data[symbolInput?.address ?? ''],
    ...(data[symbolInput?.address ?? ''] ?? {
      withdrawAmount: {
        raw: new BN(0),
        formatted: '-',
      },
      withdrawAvailableTime: undefined,
      disabledWithdrawDueTime: true,
    }),
  };
}

export default useWithdrawAmount;
