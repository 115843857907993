import { PropsWithChildren } from 'react';
import { createStorage, http, WagmiProvider } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import config from '../config';
import { WAGMI_STORAGE_KEY, WALLET_CONNECT_METADATA } from '../constants';

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = config.walletConnectProjectId;

// 2. Create wagmiConfig
const metadata = WALLET_CONNECT_METADATA;

const isMainnet = import.meta.env.VITE_ALLSTAKE_ENV !== 'devnet';
export const chain = isMainnet ? mainnet : sepolia;
const chains = [chain] as const;
export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  transports: isMainnet
    ? {
        [mainnet.id]: http(import.meta.env.VITE_ETHEREUM_MAINNET_RPC_URL),
      }
    : {
        [sepolia.id]: http(import.meta.env.VITE_ETHEREUM_TESTNET_RPC_URL),
      },
  storage: createStorage({
    key: WAGMI_STORAGE_KEY,
    storage: localStorage,
  }),
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

export function Web3ModalProvider({ children }: PropsWithChildren) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
