import clsx from 'clsx';
import flatten from 'lodash/flatten';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { twc } from 'react-twc';

import { BWhiteArrowImg, RtBlueArrowImg, YesImg } from '../../assets';
import config from '../../config';
import { SymbolInput } from '../../config/type';
import RestakeTabContext from '../../context/RestakeTabContext';
import SelectedAssets from '../../context/SelectedAssets';
import SelectedNetwork from '../../context/SelectedNetwork';
import SymbolInputContext from '../../context/SymbolInputContext';
import useQuery from '../../hooks/useQuery';
import { formatTokenNameInput, isChainNativeToken } from '../../utils/regex';
import BoostTag from '../boost-tag';
import {
  GetLink,
  InputAreaContainer,
  TokenInputContainer,
  TokenInputIcon,
  TokenInputSelectText,
  TokenLabel,
} from './styles';

const { useSymbolInputTracked } = SymbolInputContext;
const { useRestakeTabTracked } = RestakeTabContext;
const { useSelectedAssetsTracked } = SelectedAssets;
const { useSelectedNetworkTracked } = SelectedNetwork;

function TokenSelectInput() {
  const [symbolInput] = useSymbolInputTracked();
  const [select, setSelect] = useState(false);
  const [restakeTab] = useRestakeTabTracked();
  const { t } = useTranslation();

  if (!symbolInput) return null;
  return (
    <InputAreaContainer>
      <TokenLabel>{t('restake.selectToken')}</TokenLabel>
      <TokenInputContainer
        className={clsx(select && 'token-input-shadow')}
        onClick={() => setSelect(!select)}
      >
        <TokenInputIcon src={symbolInput.icon} width={20} height={20} />
        <TokenInputSelectText>{formatTokenNameInput(symbolInput.name)}</TokenInputSelectText>
        <TokenInputIcon
          className={clsx(select && 'rotate-180')}
          src={BWhiteArrowImg}
          width={20}
          height={20}
        />
        <TokenInputDropdown show={select} />
      </TokenInputContainer>
      {restakeTab === 'deposit' && symbolInput.url && !isChainNativeToken(symbolInput.name) && (
        <GetLink href={symbolInput.url} target="_blank">
          <p>
            {t('restake.get')} ${symbolInput.symbol}
          </p>
          <TokenInputIcon src={RtBlueArrowImg} width={20} height={20} />
        </GetLink>
      )}
    </InputAreaContainer>
  );
}

const TokenInputDropdown = ({ show }: { show: boolean }) => {
  const [symbolInput, setSymbolInputTracked] = useSymbolInputTracked();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const tab = query.get('tab');
  const [selectedAssets, setSelectedAssets] = useSelectedAssetsTracked();
  const [selectedNetwork, setSelectedNetwork] = useSelectedNetworkTracked();

  const { t } = useTranslation();

  if (!symbolInput) return null;
  return (
    <TokenInputDropdownContainer className={clsx(!show && 'opacity-0 pointer-events-none')}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <FilterRow>
          <FilterTitle>{t('restake.network')}</FilterTitle>
          {config.dropdownNetworks
            .filter((network) =>
              flatten(
                config.restakingTokens
                  .filter((token) =>
                    tab === 'unstake' ? token.showInUnstakeList : token.showInStakeList,
                  )
                  .map((token) => token.selectTag),
              ).includes(network),
            )
            .map((network) => {
              return (
                <FilterTag
                  key={network}
                  selected={selectedNetwork.includes(network)}
                  onClick={() => {
                    setSelectedNetwork((prev) => {
                      if (prev.length === config.dropdownNetworks.length) {
                        return [network];
                      } else if (prev.includes(network)) {
                        const filteredResult = prev.filter((n) => n !== network);
                        if (filteredResult.length === 0) {
                          return config.dropdownNetworks;
                        } else {
                          return filteredResult;
                        }
                      } else {
                        return [...prev, network];
                      }
                    });
                  }}
                >
                  {network.toUpperCase()}
                </FilterTag>
              );
            })}
        </FilterRow>
        <FilterRow>
          <FilterTitle>{t('restake.assets')}</FilterTitle>
          {config.dropdownAssets.map((asset) => {
            return (
              <FilterTag
                key={asset}
                disabled={
                  !flatten(
                    config.restakingTokens
                      .filter((token) =>
                        tab === 'unstake' ? token.showInUnstakeList : token.showInStakeList,
                      )
                      .filter((token) => selectedNetwork.includes(token.chain))
                      .map((token) => token.selectTag),
                  ).includes(asset)
                }
                selected={selectedAssets.includes(asset)}
                onClick={() => {
                  setSelectedAssets((prev) => {
                    if (prev.length === config.dropdownAssets.length) {
                      return [asset];
                    } else if (prev.includes(asset)) {
                      const filteredResult = prev.filter((n) => n !== asset);
                      if (filteredResult.length === 0) {
                        return config.dropdownAssets;
                      } else {
                        return filteredResult;
                      }
                    } else {
                      return [...prev, asset];
                    }
                  });
                }}
              >
                {asset}
              </FilterTag>
            );
          })}
        </FilterRow>
      </div>
      <hr className="h-[1px] border-none bg-[#FFFFFF24] my-2" />
      <div className="px-1 max-h-[250px] overflow-auto">
        {config.restakingTokens
          .filter((token) => (tab === 'unstake' ? token.showInUnstakeList : token.showInStakeList))
          .filter(
            (token) =>
              token.selectTag.find((tag) => selectedAssets.includes(tag)) &&
              token.selectTag.find((tag) => selectedNetwork.includes(tag)),
          )
          .map((token: SymbolInput, idx) => (
            <TokenRow
              className={clsx(symbolInput.name === token.name && 'bg-[#1F242F]')}
              key={idx}
              onClick={() => {
                setSymbolInputTracked(token);
                navigate(`${location.pathname}?symbol=${token.name}${tab ? `&tab=${tab}` : ''}`);
              }}
            >
              <div className="flex items-center gap-2">
                <img
                  className="overflow-hidden rounded-full"
                  src={token.icon}
                  width={22}
                  height={22}
                />
                <p>{formatTokenNameInput(token.name)}</p>
                {token.boostTag.map((tag) => (
                  <BoostTag key={tag}>{tag}</BoostTag>
                ))}
              </div>
              {symbolInput.name === token.name && <img src={YesImg} alt="Yes" />}
            </TokenRow>
          ))}
      </div>
    </TokenInputDropdownContainer>
  );
};

const FilterTag = ({
  children,
  selected,
  onClick,
  disabled,
}: PropsWithChildren & {
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <FilterTagStyle
      className={clsx(
        selected && !disabled ? 'bg-[#4565E5]' : 'bg-[#1F242F]',
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer',
      )}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {children}
    </FilterTagStyle>
  );
};

const FilterTagStyle = twc.button`
  px-2 py-1 text-white text-sm rounded-lg
`;

const FilterTitle = twc.div`
  text-white
`;

const FilterRow = twc.div`
  py-2 px-4 flex gap-3 cursor-default
`;

const TokenInputDropdownContainer = twc.div`
  absolute z-10 left-0 top-[70px] w-full
  bg-[#0C111D] rounded-md
  flex flex-col
  border border-[#1F242F] py-1
  duration-300
`;

const TokenRow = twc.div`
  p-2 flex justify-between
  gap-2 hover:bg-[#1F242F]
  rounded-md duration-100
  cursor-pointer
`;
export default TokenSelectInput;
