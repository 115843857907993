import devnet from "./devnet";
import production from "./production";
import staging from "./staging";

const config =
  {
    devnet,
    staging,
    production,
  }[import.meta.env.VITE_ALLSTAKE_ENV || "devnet"] || "devnet";

const filteredUnsupportedTokens = config.restakingTokens.filter((token) =>
  config.supportNetworks.includes(token.chain),
);
export default {
  ...config,
  restakingTokens: filteredUnsupportedTokens,
};
