import { useMemo } from 'react';

import BalanceContext from '@/context/BalanceContext';
import RestakeTabContext from '@/context/RestakeTabContext';
import SymbolInputContext from '@/context/SymbolInputContext';

const { useBalanceTracked } = BalanceContext;
const { useSymbolInputTracked } = SymbolInputContext;
const { useRestakeTabTracked } = RestakeTabContext;

function useShownBalance() {
  const [balance] = useBalanceTracked();
  const [symbolInput] = useSymbolInputTracked();
  const [tab] = useRestakeTabTracked();

  const shownBalance = useMemo(() => {
    if (!symbolInput?.address) return;
    if (tab === 'deposit') return balance[symbolInput.address]?.tokenBalance;
    else if (tab === 'unstake') return balance[symbolInput.address]?.stakedToken;
    else return;
  }, [symbolInput?.address, balance, tab]);

  return shownBalance;
}

export default useShownBalance;
