import { useMemo } from 'react';

import BalanceContext from '@/context/BalanceContext';
import SymbolInputContext from '@/context/SymbolInputContext';

const { useBalanceTracked } = BalanceContext;
const { useSymbolInputTracked } = SymbolInputContext;

export function useBalance() {
  const [balance] = useBalanceTracked();
  const [symbolInput] = useSymbolInputTracked();

  const balanceKey = useMemo(() => {
    if (!symbolInput?.address) return;
    const balanceValue = balance[symbolInput.address];
    return `${symbolInput.address}-${balanceValue?.tokenBalance || 0}-${balanceValue?.stakedToken || 0}`;
  }, [symbolInput?.address, balance]);

  return {
    balanceKey,
  };
}
