import '@/index.scss';
import '@near-wallet-selector/modal-ui/styles.css';
import 'react-slideshow-image/dist/styles.css';

import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

import App from '@/App.tsx';
import { WalletSelectorContextProvider } from '@/context/WalletSelectorContext.tsx';
import { Web3ModalProvider } from '@/context/Web3ModalProvider.tsx';
import { initSentry } from '@/utils/sentry';

if (import.meta.env.VITE_DISABLED_SENTRY !== 'true') {
  initSentry();
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Web3ModalProvider>
    <WalletSelectorContextProvider>
      <App />
      <Toaster />
    </WalletSelectorContextProvider>
  </Web3ModalProvider>,
);
