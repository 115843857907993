import { AllstakeSdk, initAllstakeSdk, MultiSendAdapter } from "allstake-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PublicClient } from "viem";
import { useAccount, usePublicClient, UsePublicClientReturnType, useWalletClient } from "wagmi";

import { AnchorProvider } from "@coral-xyz/anchor";
import { AnchorWallet, useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";

import config from "../config";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { createContainer } from "../hooks/useContainer";
import { connection } from "../utils/rpc";

interface AllstakeState {
  sdk: AllstakeSdk | null;
  wallet?: AnchorWallet;
  provider: AnchorProvider;
  publicClient: UsePublicClientReturnType;
}

type UseAllstakeContainer = AllstakeState;

function useAllstakeContainer(): UseAllstakeContainer {
  const [sdk, setSdk] = useState<AllstakeSdk | null>(null);

  const { connected: walletConnected, autoConnect: walletAutoConnect } = useWallet();
  const wallet = useAnchorWallet();

  const readyForInitializeSdk = useMemo(() => {
    if (walletAutoConnect) return walletConnected;
    return true;
  }, [walletAutoConnect, walletConnected]);

  const provider = useMemo(
    () => new AnchorProvider(connection, wallet as any, {}),
    [wallet?.publicKey?.toBase58()],
  );
  const { selector } = useWalletSelector();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { address: ethAddress } = useAccount();

  const setupSdk = useCallback(async () => {
    const _sdk = await initAllstakeSdk({
      near: {
        ...config.allstakeSdkOptions.near,
        adapter: MultiSendAdapter.fromWalletSelector(selector),
      },
      solana: {
        ...config.allstakeSdkOptions.solana,
        provider,
      },
      ethereum: {
        ...config.allstakeSdkOptions.ethereum,
        publicClient: publicClient as PublicClient,
      },
      pyth: config.pyth,
      coingecko: config.coingecko,
    });

    setSdk(_sdk);
  }, [provider, publicClient, selector])

  useEffect(() => {
    if (!sdk?.ethereum?.strategyManagerContract || !walletClient) return;
    sdk.ethereum.strategyManagerContract.setWalletClient(walletClient);
  }, [sdk?.ethereum?.strategyManagerContract, walletClient, ethAddress]);

  useEffect(() => {
    if (!readyForInitializeSdk) return
    setupSdk();
  }, [readyForInitializeSdk, setupSdk]);

  return {
    sdk,
    wallet,
    provider,
    publicClient,
  };
}

const AllstakeContainer = createContainer(useAllstakeContainer);

export { AllstakeContainer };
