import { Amount, BigNumber, throwReceiptErrorsFromOutcomes } from 'allstake-sdk';
import Big from 'big.js';
import { BN } from 'bn.js';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { twc } from 'react-twc';
import { useAccount, useWalletClient } from 'wagmi';

import config from '@/config';
import { SymbolInput } from '@/config/type';
import { UNSTAKE_INTERVAL } from '@/constants';
import { AllstakeContainer } from '@/context/AllstakeContext';
import RefreshBalanceContext from '@/context/RefreshBalanceContext';
import RestakeTabContext from '@/context/RestakeTabContext';
import SymbolInputContext from '@/context/SymbolInputContext';
import TokenInputContext from '@/context/TokenInputContext';
import { useWalletSelector } from '@/context/WalletSelectorContext';
import useATokenAddress from '@/hooks/useATokenAddress';
import useChain from '@/hooks/useChain';
import useQuery from '@/hooks/useQuery';
import useShownBalance from '@/hooks/useShownBalance';
import useWithdrawAmount from '@/hooks/useWithdrawAmount';
import { formatToReadableError } from '@/utils/error';
import { getMethodNamesFromArgs } from '@/utils/near';
import { getPairPrice } from '@/utils/price';
import { extractToTokenName, extractToTokenNameByName, isDigit } from '@/utils/regex';
import { SECOND, sleep } from '@/utils/time';
import toLocaleString from '@/utils/toLocaleString';
import { resetWagmiState } from '@/utils/wagmi';
import { getNear } from '@/utils/wallet';
import { base64 } from '@coral-xyz/anchor/dist/cjs/utils/bytes';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import AllstakeModal from '../modal';
import FormTagSelector from './FormTagSelector';
import StakeButton from './StakeButton';
import TokenAmountInput from './TokenAmountInput';
import TokenSelectInput from './TokenSelectInput';
import { ModalTypes } from './types';
import { useDeposit } from './useDeposit';
import { useUnstake } from './useUnstake';

const { useSymbolInputTracked } = SymbolInputContext;
const { useTokenInputTracked } = TokenInputContext;
const { useRestakeTabTracked } = RestakeTabContext;
const { useRefreshBalanceTracked } = RefreshBalanceContext;

function RestakingForm() {
  const { accountId } = useWalletSelector();
  const [symbolInput, setSymbolInput] = useSymbolInputTracked();
  const [tokenInput, setTokenInput] = useTokenInputTracked();
  const { sdk: allstakeSdk } = AllstakeContainer.useContainer();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [pairPrice, setPairPrice] = useState(BigNumber(0));
  const [showModal, setShowModal] = useState<ModalTypes>();
  const [error, setError] = useState('');
  const [restakeTab] = useRestakeTabTracked();
  const [, setRefreshBalance] = useRefreshBalanceTracked();
  const shownBalance = useShownBalance();
  const insufficientBalance = !!(
    shownBalance &&
    isDigit(shownBalance) &&
    isDigit(tokenInput) &&
    Big(tokenInput).gt(shownBalance)
  );
  const { withdrawAmount, disabledWithdrawDueTime, dataCompleted } = useWithdrawAmount();
  const navigate = useNavigate();
  const chain = useChain();
  const { modal } = useWalletSelector();
  const { open: ethModalOpen } = useWeb3Modal();

  const { t } = useTranslation();

  const query = useQuery();
  const symbol = query.get('symbol');
  const txHashes = query.get('transactionHashes');
  const errorCode = query.get('errorCode');
  const { pathname } = useLocation();

  const ethAccount = useAccount();
  const { data: walletClient } = useWalletClient();

  const { onDeposit } = useDeposit({
    onToggleLoadingModal: setLoadingModal,
    onStart() {
      setLoading(true);
    },
    onFinish() {
      setLoading(false);
      setLoadingModal(false);
    },
    onSuccess(resp) {
      setShowModal(resp.modalType);
      setTokenInput(resp.newTokenInput);
      if (resp.newSymbolInput) {
        // Changing the symbol input also triggers a refresh
        setSymbolInput(resp.newSymbolInput);
      } else {
        setRefreshBalance(true);
      }
    },
    onError(err, showModal) {
      if (showModal) setShowModal('error');
      setError(err);
    },
  });

  const { onUnstake } = useUnstake({
    onStart() {
      setLoading(true);
    },
    onFinish() {
      setLoading(false);
      setLoadingModal(false);
    },
    onSuccess(resp) {
      setShowModal(resp.modalType);
      setTokenInput(resp.newTokenInput);
      setRefreshBalance(true);
    },
    onError(err, showModal) {
      if (showModal) setShowModal('error');
      setError(err);
    },
  });

  useEffect(() => {
    if (!allstakeSdk || !symbolInput) return;
    const stakeToName = extractToTokenName(symbolInput);
    getPairPrice(
      allstakeSdk,
      symbolInput.address,
      symbolInput.symbol,
      symbolInput.address,
      stakeToName,
    ).then((price) => {
      setPairPrice(price);
    });
  }, [allstakeSdk, symbolInput]);

  const onClickDeposit = async () => {
    if (!symbolInput) return;
    onDeposit({ symbolInput, tokenInput });
  };

  const onClickUnstake = async () => {
    if (!symbolInput) return;
    onUnstake({ symbolInput, tokenInput, shownBalance });
  };

  async function parseTransaction() {
    if (errorCode) {
      navigate(`${pathname}${symbol ? `?symbol=${symbol}` : ''}`);
    } else {
      if (!txHashes || !accountId) return;
      if (txHashes) {
        const near = await getNear();
        const navigatePathname = pathname;
        try {
          const outcomes = await Promise.all(
            txHashes
              .split(',')
              .map(async (txHash) => await near.connection.provider.txStatus(txHash, accountId)),
          );
          throwReceiptErrorsFromOutcomes(outcomes);
          const methodNames = await getMethodNamesFromArgs(txHashes.split(','), accountId);
          if (methodNames.find((methodName) => methodName.startsWith('queue_withdrawals'))) {
            // It can only be obtained from the url because the received id is incorrect
            navigate(`${navigatePathname}?tab=unstake${symbol ? `&symbol=${symbol}` : ''}`);
          } else if (methodNames.find((methodName) => methodName.startsWith('ft_transfer_call'))) {
            const methodName = methodNames.find((methodName) =>
              methodName.startsWith('ft_transfer_call'),
            )!;
            // It cannot be obtained by url, because near -> linear does not take a url params
            navigate(`${navigatePathname}?symbol=${methodName.split(',')[1]}`);
          } else {
            navigate(`${navigatePathname}${symbol ? `?symbol=${symbol}` : ''}`);
          }
          if (
            methodNames.find(
              (methodName) =>
                methodName.startsWith('ft_transfer_call') ||
                methodName.startsWith('queue_withdrawals'),
            )
          ) {
            setShowModal('deposit_success');
          } else if (methodNames.find((methodName) => methodName.startsWith('deposit_and_stake'))) {
            const methodName = methodNames.find((methodName) =>
              methodName.startsWith('deposit_and_stake'),
            );
            if (methodName) {
              const splits = methodName.split(',');
              const tokenName = splits[1];
              const updateSymbolInput = config.restakingTokens.find(
                (token) => token.name === tokenName,
              );
              if (tokenName && updateSymbolInput) {
                navigate(`/?symbol=${updateSymbolInput.name}`);
                try {
                  const value = JSON.parse(
                    base64.decode((outcomes[0].status as any).SuccessValue).toString(),
                  );
                  const formattedValue = Amount.format(value, updateSymbolInput.decimals);
                  setTimeout(() => {
                    setTokenInput(formattedValue);
                    setShowModal('restake_success');
                  }, 1 * SECOND);
                } catch (e) {
                  console.error(e);
                }
                setShowModal('stake_near_success');
              }
            }
          }
        } catch (e) {
          if (e instanceof Error && /Transaction\s([a-zA-Z0-9]+)\sdoesn't\sexist/.test(e.message)) {
            console.warn(`${e.message}, retring in 3 seconds`);
            await sleep(3 * SECOND);
            await parseTransaction();
          } else {
            setShowModal('error');
            setError(formatToReadableError(e, t));
          }
          throw e;
        }
      }
    }
  }
  const parseTransactionRef = useRef(parseTransaction);
  parseTransactionRef.current = parseTransaction;

  useEffect(() => {
    parseTransactionRef.current();
  }, [symbolInput, txHashes, accountId]);

  const depositButtonText = getDepositButtonText(symbolInput, insufficientBalance, t);

  const aToken = useATokenAddress();

  if (!symbolInput || !chain) return null;

  return (
    <RestakingFormContainer>
      <FormTagSelector />
      <TokenSelectInput />
      <TokenAmountInput />
      <div className="flex flex-col items-center justify-center">
        {!chain.isSignedIn && chain.chainId === 'solana' && (
          <WalletMultiButton style={{ width: '260px', height: '56px' }} />
        )}
        {!chain.isSignedIn && chain.chainId === 'near' && (
          <ConnectButton
            onClick={() => {
              modal.show();
            }}
          >
            {t('header.selectWallet')}
          </ConnectButton>
        )}
        {!chain.isSignedIn && chain.chainId === 'eth' && (
          <ConnectButton
            onClick={() => {
              if (!walletClient && ethAccount.isConnected) {
                // reset wagmi state
                resetWagmiState();
                return;
              }
              ethModalOpen();
            }}
          >
            {t('header.selectWallet')}
          </ConnectButton>
        )}
        {chain.isSignedIn && restakeTab === 'deposit' && (
          <StakeButton
            className={clsx(depositButtonText.length > 25 && '!w-[320px]')}
            disabled={
              !isDigit(tokenInput) ||
              Big(tokenInput).lt(symbolInput.minAmount) ||
              insufficientBalance
            }
            loading={loading}
            onClick={onClickDeposit}
          >
            {depositButtonText}
          </StakeButton>
        )}
        {chain.isSignedIn && restakeTab === 'unstake' && (
          <StakeButton
            disabled={
              !isDigit(tokenInput) ||
              Big(tokenInput).lte(0) ||
              insufficientBalance ||
              (chain.chainId === 'eth' && !dataCompleted)
            }
            loading={loading}
            onClick={async () => {
              if (withdrawAmount.raw.gt(new BN(0)) && !disabledWithdrawDueTime) {
                setShowModal('goToWithdraw');
              } else {
                setShowModal('confirmUnstake');
              }
            }}
          >
            {insufficientBalance ? 'Insufficient Balance' : 'Unstake'}
          </StakeButton>
        )}
        {restakeTab === 'unstake' && (
          <UnstakedText>
            {t('restake.unstakeInDays', {
              days: UNSTAKE_INTERVAL,
            })}
          </UnstakedText>
        )}
      </div>
      {loadingModal && (
        <AllstakeModal
          varient="loading"
          onRequestClose={() => setLoadingModal(false)}
          title=""
          description={t('modal.stakeTokenAToTokenB', {
            tokenA: `${toLocaleString(tokenInput, 4)} <img class="inline-block h-5 w-5" src="${symbolInput.icon}" /> ${symbolInput.symbol}`,
            tokenB: `${
              pairPrice.eq(0) || !isDigit(tokenInput)
                ? '-'
                : toLocaleString(pairPrice.times(BigNumber(Number(tokenInput))).toFixed(), 4)
            } <img class="inline-block h-5 w-5" src="${config.restakingTokens.find((token) => token.name === extractToTokenName(symbolInput))?.icon}" /> ${extractToTokenName(symbolInput)}`,
          })}
          buttons={[]}
        />
      )}
      {showModal === 'goToWithdraw' && (
        <AllstakeModal
          varient="warn"
          onRequestClose={() => setShowModal(undefined)}
          title="modal.goToWithdrawTitle"
          description={t('modal.goToWithdrawDescription', {
            symbol: symbolInput.symbol,
          })}
          buttons={[
            {
              varient: 'outline',
              text: 'modal.back',
              onClick: () => setShowModal(undefined),
            },
            {
              varient: 'primary',
              text: 'modal.goToWithdraw',
              onClick: () => {
                const newQuery = new URLSearchParams();
                const symbol = query.get('symbol');
                const chain = query.get('chain');
                if (symbol) {
                  newQuery.set('symbol', extractToTokenNameByName(symbol));
                } else if (chain) {
                  newQuery.set('chain', chain);
                }
                navigate(
                  `/portfolio${newQuery.toString() !== '' ? `?${newQuery.toString()}` : ''}`,
                );
              },
            },
          ]}
        />
      )}
      <div style={{ display: 'none' }}>{chain.chainId}</div>
      {showModal === 'confirmUnstake' && (
        <AllstakeModal
          varient="warn"
          onRequestClose={() => setShowModal(undefined)}
          title="modal.startUnstakeTitle"
          description={t('modal.startUnstakeDescription', {
            days: UNSTAKE_INTERVAL,
          })}
          buttons={[
            {
              varient: 'outline',
              text: 'modal.cancel',
              disabled: loading,
              onClick: () => setShowModal(undefined),
            },
            {
              varient: 'primary',
              text: 'modal.confirm',
              loading: loading,
              onClick: () => onClickUnstake(),
            },
          ]}
        />
      )}
      {showModal === 'addTokenToWalletSuccess' && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={t('error.addTokenToWalletSuccess', {
            token: aToken?.symbol,
          })}
          description=""
          buttons={[
            {
              varient: 'primary',
              text: 'modal.gotIt',
              onClick: () => setShowModal(undefined),
            },
          ]}
        />
      )}
      {showModal === 'addTokenToWalletFailed' && (
        <AllstakeModal
          varient="error"
          onRequestClose={() => setShowModal(undefined)}
          title={t('error.addTokenToWalletFailed', {
            token: aToken?.symbol,
          })}
          description=""
          buttons={[
            {
              varient: 'primary',
              text: 'modal.gotIt',
              onClick: () => setShowModal(undefined),
            },
          ]}
        />
      )}
      {showModal === 'restake_success' && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={`${t('modal.restakeReceived')} ${toLocaleString(tokenInput, 4)} <img class="inline-block h-6 w-6" src="${symbolInput.icon}" /> ${symbolInput.symbol}`}
          description=""
          buttons={[
            {
              varient: 'primary',
              text: 'modal.continue',
              onClick: () => {
                setShowModal(undefined);
                onClickDeposit();
              },
            },
          ]}
        />
      )}
      {showModal === 'deposit_success' && (
        <AllstakeModal
          varient="success"
          onRequestClose={() => setShowModal(undefined)}
          title={`${restakeTab === 'deposit' ? 'modal.depositSuccess' : t('modal.unstakingHasStarted', { days: UNSTAKE_INTERVAL })}`}
          description=""
          buttonGroupCol
          buttons={
            restakeTab === 'deposit' && chain.chainId === 'eth'
              ? [
                  {
                    varient: 'primary',
                    text: 'modal.gotIt',
                    onClick: () => setShowModal(undefined),
                  },
                  {
                    varient: 'outline',
                    text: t('modal.addReceiptTokenToWallet', {
                      token: aToken?.symbol,
                    }),
                    onClick: async () => {
                      if (!walletClient) {
                        console.warn('Wallet not connected');
                        return;
                      }

                      if (!aToken) {
                        console.error('aTokenAddress is not fetched');
                        return;
                      }
                      try {
                        const watchAssetResult = await walletClient.watchAsset({
                          type: 'ERC20',
                          options: {
                            address: aToken.address,
                            symbol: aToken.symbol,
                            decimals: aToken.decimals,
                          },
                        });

                        if (watchAssetResult) {
                          setShowModal('addTokenToWalletSuccess');
                        } else {
                          setShowModal('addTokenToWalletFailed');
                        }
                      } catch (error) {
                        setShowModal('addTokenToWalletFailed');
                        throw error;
                      }
                    },
                  },
                ]
              : [
                  {
                    varient: 'primary',
                    text: 'modal.gotIt',
                    onClick: () => setShowModal(undefined),
                  },
                ]
          }
        />
      )}
      {showModal === 'error' && (
        <AllstakeModal
          varient="error"
          onRequestClose={() => {
            setShowModal(undefined);
            setLoadingModal(false);
          }}
          title={`${restakeTab === 'deposit' ? 'modal.depositFailure' : 'modal.unstakeFailure'}`}
          description={error}
          buttons={[
            {
              varient: 'outline',
              text: 'modal.back',
              onClick: () => {
                setShowModal(undefined);
                setLoadingModal(false);
              },
            },
          ]}
        />
      )}
    </RestakingFormContainer>
  );
}

function getDepositButtonText(
  symbolInput: SymbolInput | null,
  isInsufficientBalance: boolean,
  t: TFunction<'translation', undefined>,
) {
  if (!symbolInput) return '';
  if (isInsufficientBalance) {
    return t('restake.insufficientBalance');
  } else if (symbolInput.symbol === 'NEAR' || symbolInput.symbol === 'SOL') {
    return t('restake.stakeToTokenAndRestake', {
      token: extractToTokenName(symbolInput),
    });
  } else {
    return t('restake.deposit');
  }
}

const ConnectButton = twc.button`
  w-[260px] h-[56px]
  text-base font-semibold
  rounded-full bg-[#4565E5]
`;

const RestakingFormContainer = twc.div`
  bg-[#0C111D] p-10
  flex flex-col gap-8
  min-w-[660px] rounded-xl
`;

const UnstakedText = twc.div`
  text-white text-opacity-40 text-sm font-medium
  flex justify-center mt-[10px]
`;

export default RestakingForm;
