import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://cd12acacb066e4913031765c823e038e@o4507382761783296.ingest.us.sentry.io/4507383380574208",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/allstake-ui-pr-.*.onrender\.com/,
      /.*\.*allstake\.org/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
