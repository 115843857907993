import { useState, useEffect } from "react";
import { usePublicClient } from "wagmi";
import SymbolInputContext from "../context/SymbolInputContext";
import { Address, erc20Abi, getContract } from "viem";
import { AllstakeContainer } from "../context/AllstakeContext";

const { useSymbolInputTracked } = SymbolInputContext;

function useATokenAddress() {
  const { sdk: allstakeSdk } = AllstakeContainer.useContainer();
  const [symbolInput] = useSymbolInputTracked();

  const [aToken, setAToken] = useState<{
    symbol: string;
    address: string;
    decimals: number;
  }>();

  const publicClient = usePublicClient();

  useEffect(() => {
    // only support eth
    if (symbolInput?.chain !== "eth") {
      return;
    }
    (async function () {
      if (
        !allstakeSdk ||
        !allstakeSdk.ethereum ||
        !symbolInput.address ||
        !publicClient
      ) {
        return;
      }
      const strategyData =
        await allstakeSdk.ethereum.uiDataProviderContract.strategyDataByToken(
          symbolInput.address,
        );
      const aTokenAddress = strategyData.strategyAddress;

      // get a token address info
      const erc20Contract = getContract({
        address: aTokenAddress as Address,
        abi: erc20Abi,
        client: publicClient,
      });

      const [symbol, decimals] = await Promise.all([
        erc20Contract.read.symbol(),
        erc20Contract.read.decimals(),
      ]);

      setAToken({
        symbol,
        address: aTokenAddress,
        decimals,
      });
    })();
  }, [symbolInput, allstakeSdk, publicClient]);
  return aToken;
}

export default useATokenAddress;
